// getHomeListItems.js
'use strict';
import { Collection } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';

import getListData from '../selector/getListData.js';
import { showcaseDesktopMinimumItemAmount } from '../style/variables.js';

/**
 * get home list
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @return {array} The selected home list.
 */
const getHomeListItems = createCachedSelector(
  (state, showcaseType) =>
    getListData(state, ['home', 'messages', showcaseType], 'itemIds'),
  state => getListData(state, ['home', 'messages', 'home'], 'itemIds'),
  () => showcaseDesktopMinimumItemAmount,
  (featuredItemIds = [], homeItemIds = [], minimumAmount) => {
    let result = homeItemIds;

    // if showcase amount is less than showcaseDesktopMinimumItemAmount, then showcase will fill up with home. home need to remove these ids.
    const delta = minimumAmount - featuredItemIds.length;

    if (delta > 0) {
      result = homeItemIds.slice(delta);
    }

    return result instanceof Collection ? result.toJS() : result;
  }
)(() => 'getHomeListItems');

export default getHomeListItems;
